<template>
  <div class="w-100 new-comment-box quill-editor position-relative">
    <textarea :value="cleanContent" :name="name" :id="id" v-show="false">
      </textarea>
    <!-- <div :id="`editor-${id}`" class="w-100">
      </div> -->
    <quill-editor v-model="content" :content="value" :class="className" name="body" :options="editorOption"
      ref="myQuillEditor" class="regular-input" @input="(val) => $emit('update:value', val)">
    </quill-editor>
    <div :id="`toolbar-${id}`" class="ql-snow ql-toolbar">
      <span class="ql-formats">
        <button class="ql-image">
          <!-- <b-img
              style="width: 1.5rem"
              :src="require('@/assets/images/pages/imageicon.svg')"
              alt="logo"
            /> -->
        </button>
        <button @click="toogleDialogEmoji" class="">
          <b-img style="width: 1.5rem" :src="require('@/assets/images/pages/smile-icon.svg')" alt="logo" />
        </button>
      </span>
    </div>
    <feather-icon class="edit-close position-absolute" icon="XIcon" size="18" v-if="content" @click="closeButton" />
    <!-- <div > -->
    <VEmojiPicker :pack="emojisNative" labelSearch="Search" @select="onSelectEmoji" class="emoji-picker"
      :hidden="dialogHidden" />
    <!-- </div> -->
  </div>
</template>
  
<script>
import "quill-mention/dist/quill.mention.min.css";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import "quill-emoji/dist/quill-emoji.css";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import { quillEditor } from "vue-quill-editor";
import ImageCompress from "quill-image-compress";

import VEmojiPicker from "v-emoji-picker";
import packEmoji from "v-emoji-picker/data/emojis.js";

import Quill from "quill";
window.Quill = Quill;
import Mention from "quill-mention";
Quill.register("modules/mention", Mention);
Quill.register("modules/imageCompress", ImageCompress);
import ImageResize from "quill-image-resize";
Quill.register("modules/imageResize", ImageResize);
import * as Emoji from "quill-emoji";
Quill.register("modules/emoji", Emoji);

const Link = Quill.import("formats/link");

class linkType extends Link {
  static create(value) {
    let node = super.create(value);
    value = this.sanitize(value);
    if (value.startsWith("https://") || value.startsWith("http://")) {
      node.className = "link--external";
      node.setAttribute("target", "_blank");
    } else {
      node.setAttribute("target", "_blank");
    }
    return node;
  }
}
Quill.register(linkType);

//AutoDetect URLs
import QuillAutoDetectUrl, {
  QuillAutoDetectUrlOptions,
} from "quill-auto-detect-url";

Quill.register("modules/autoDetectUrl", QuillAutoDetectUrl);

const emojiBlot = Quill.import("blots/embed");
class EmojiBlot extends emojiBlot {
  static create(value) {
    const node = super.create(value);
    node.innerHTML = value;
    return node;
  }
}
EmojiBlot.blotName = "emoji";
EmojiBlot.className = "ql-emoji";
EmojiBlot.tagName = "span";
Quill.register(EmojiBlot);

import $ from "jquery";
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BForm,
  BFormCheckbox,
} from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormCheckbox,
    BForm,
    quillEditor,
    QuillAutoDetectUrlOptions,
    VEmojiPicker,
  },
  name: "ChatInputBox",
  props: {
    value: {
      type: String,
      required: false,
    },
    id: {
      type: String,
      required: false,
    },
    className: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      required: false,
    },
    toolbar: {
      type: Boolean,
      required: true,
    },
    mentionList: {
      required: true,
    },
    clearInput: {
      required: false,
    },
    name: { type: String, required: false, default: "" },
  },
  directives: {
    focus: {
      inserted(el) {
        el.focus();
      },
    },
  },
  mounted: function () {
    let options = {
      //debug: "info",
      modules: {
        toolbar: "#toolbar-" + this.id,
        mention: {
          mentionListClass: "ql-mention-list custom-mention-list",
          allowedChars: /^[a-zA-Z0-9_]*$/,
          mentionDenotationChars: ["@"],
          dataAttributes: [
            "id",
            "value",
            "denotationChar",
            "link",
            "target",
            "disabled",
            "hover",
          ],
          offsetLeft: $("body").hasClass("rtl") ? -250 : 0,
          renderItem: (item) => {
            return item.display;
          },
          source: (searchTerm, renderList, mentionChar) => {
            let values = this.mentionList;

            if (searchTerm.length === 0) {
              renderList(values, searchTerm);
            } else {
              const matches = [];
              for (let i = 0; i < values.length; i++)
                if (
                  ~values[i].value
                    .toLowerCase()
                    .indexOf(searchTerm.toLowerCase())
                )
                  matches.push(values[i]);
              renderList(matches, searchTerm);
            }
          },
        },
        autoDetectUrl: true,
        imageCompress: {
          quality: 0.8, // default
          maxWidth: 512, // default
          maxHeight: 512, // default
          imageType: "image/jpeg", // default
          debug: false, // default
          suppressErrorLogging: false, // default
          insertIntoEditor: undefined, // default
        },
      },
      placeholder: this.placeholder,
      theme: "snow",
    };
    let id = "#editor-" + this.id;

    if (this.value) {
      this.editor = this.$refs.myQuillEditor.quill;
      this.editor.root.innerHTML = this.value;
    }
  },
  data() {
    return {
      editor: null,
      body: this.value,
      editorOption: {
        modules: {
          toolbar: "#toolbar-" + this.id,
          // toolbar: ["image", ""],
          // "emoji-toolbar": true,
          // "emoji-textarea": false,
          // "emoji-shortname": false,
          mention: {
            mentionListClass: "ql-mention-list custom-mention-list",
            allowedChars: /^[a-zA-Z0-9_]*$/,
            mentionDenotationChars: ["@"],
            dataAttributes: [
              "id",
              "value",
              "denotationChar",
              "link",
              "target",
              "disabled",
              "hover",
            ],
            offsetLeft: $("body").hasClass("rtl") ? -250 : 0,
            renderItem: (item) => {
              return item.display;
            },
            source: (searchTerm, renderList, mentionChar) => {
              let values = this.mentionList;

              if (searchTerm.length === 0) {
                renderList(values, searchTerm);
              } else {
                const matches = [];
                for (let i = 0; i < values.length; i++)
                  if (
                    ~values[i].value
                      .toLowerCase()
                      .indexOf(searchTerm.toLowerCase())
                  )
                    matches.push(values[i]);
                renderList(matches, searchTerm);
              }
            },
          },
          autoDetectUrl: true,
          imageCompress: {
            quality: 0.8, // default
            maxWidth: 512, // default
            maxHeight: 512, // default
            imageType: "image/jpeg", // default
            debug: false, // default
            suppressErrorLogging: false, // default
            insertIntoEditor: undefined, // default
          },
        },
        theme: "snow", // or 'bubble'
        content: "",
        placeholder: this.placeholder,
        id: this.id,
      },
      content: "",
      input: "",
      search: "",
      dialogHidden: true,
    };
  },
  created() {
    if (this.toolbar === "false") this.editorOption.modules.toolbar = false;

    // this.content = this.value;
  },
  computed: {
    cleanContent() {
      if (this.content) {
        return this.content;
        // let regex = /(<([^>]+)>)/gi;
        // return this.content.replace(regex, "");
      }
      return "";
    },
    emojisNative() {
      return packEmoji;
    },
    // data() {
    //   if (!this.editor) return "";
    //   return this.editor.getText();
    // },
    counter() {
      return this.data.length - 1;
    },
  },
  methods: {
    toogleDialogEmoji() {
      this.dialogHidden = !this.dialogHidden;
    },
    onSelectEmoji(dataEmoji) {
      this.editor = this.$refs.myQuillEditor.quill;
      const range = this.editor.getSelection();
      if (range) {
        this.editor.insertEmbed(range.index, "emoji", dataEmoji.emoji);
        this.editor.setSelection(range.index + 1);
      } else {
        const length = this.editor.getLength();
        this.editor.insertEmbed(length, "emoji", dataEmoji.emoji);
        this.editor.setSelection(length + 1);
      }

      this.toogleDialogEmoji();
    },
    closeButton() {
      this.content = '';
    },
    append(emoji) {
      if (this.content) {
        let tempDiv = document.createElement("div");
        tempDiv.innerHTML = this.content;

        // Find the <p> element and add the name
        let pElement = tempDiv.querySelector("p");
        pElement.innerHTML += " " + emoji;

        // Get the modified string with the name added
        let modifiedString = tempDiv.innerHTML;
        this.content = modifiedString;
      } else {
        this.content = emoji;
      }
    },
  },
  watch: {
    clearInput(newVal) {
      if (newVal) {
        this.content = '';
      }
    }
  }
};
</script>
  
<style>
.comment-details .ql-toolbar {
  border: 0 !important;
  /* position: absolute; */
  right: 0;
  top: -9px;
}

.comment-box {
  /* position: relative; */
  width: 100% !important;
  padding-right: 10px !important;
  /* right: 0 !important; */
}

.ql-snow.ql-toolbar button,
.ql-snow .ql-toolbar button {
  display: inline-block;
  height: 30px;
  width: 30px;
}

.ql-container.ql-snow {
  top: 0;
  border: 0;
}

.emoji-invoker,
.ql-emoji-invoker::before {
  display: inline-block;
  background-image: url("../assets/images/pages/smile-icon.svg");
}

.new-comment-box {
  display: flex;
  align-items: end;
}

.new-comment-box input {
  display: none;
}

.new-comment-box .ql-formats,
.new-comment-box .ql-snow.ql-toolbar {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.container-emoji {
  height: 230px !important;
}

.container-emoji .grid-emojis {
  grid-template-columns: repeat(6, 16.65%) !important;
}

.emoji {
  padding: 4px !important;
  font-size: 22px !important;
  /* height: 30px; */
}

.new-comment-box .ql-snow.ql-toolbar {
  margin: 5px 0;
  border: 0;
}

#EmojiPicker.emoji-picker {
  box-shadow: 1px 1px 8px #4e4e4e;
  background: #ffffff;
}

#EmojiPicker.emoji-picker #Categories .category {
  padding-top: 10px;
  padding-bottom: 10px;
}

#EmojiPicker.emoji-picker #Categories .category.active {
  padding-bottom: 10px;
}

.dark-layout #EmojiPicker.emoji-picker {
  box-shadow: 1px 1px 8px #4e4e4e;
  background: #2a3038;
  border: 1px solid #000000;
}

.dark-layout #EmojiPicker.emoji-picker #Categories {
  border-bottom: 1px solid #3b4253;
  background: #191c24;
}
</style>
  